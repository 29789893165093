<template>
  <div class="card elevation-5">
    <div class="pa-5">
      <p class="color-letra">{{ nome_evento }}</p>
      <p>{{ titulo }}</p>
      <p>{{ descricao }}</p>
      <p class="color-letra">{{ dataFormatada(data_criacao) }}</p>
    </div>
  </div>

</template>

<script>
import moment from "moment";

export default {
  name: "CardDocument",
  props: [
    "titulo",
    "descricao",
    "nome_evento",
    "codigo_tipo_evento",
    "data_criacao"
  ],
  methods: {
    removeFile() {
      this.$emit("clickRemove", true);

    },
    dataFormatada(data) {
      moment.locale('pt-br')
      return moment(data).format('DD/MM/YYYY')
    },
  },
};
</script>

<style scoped>
.card {
  border-radius: 10px;
  border-left: 10px solid green;
}

.color-letra {
  color: darkgray;
}
</style>
